import React, { useState } from 'react';
import logo from "./assets/multiconcepts.jpg";
import logo1 from "./assets/Bidyuth advertisement.jpg";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga'; // Import ReactGA

const Header = () => {
  ReactGA.initialize('G-3B1EJBPJBH'); // Initialize Google Analytics with your tracking ID

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const trackLinkClick = (label) => {
    // Track link clicks in Google Analytics
    ReactGA.event({
      category: 'Link Click',
      action: 'Click',
      label: label, // Use a descriptive label for your link
    });
  };

  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <header className="top-0 left-0 right-0 bg-transparent z-10">
      <div className="max-w-screen-3xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/" className="flex items-center">
          <img
            src={logo}
            className="h-16 mr-3"
            alt="multiconcepts Logo"
          />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
            <b></b>
          </span>
        </Link>
        <div className="flex md:order-2">
          <img
            src={logo1}
            alt="Multiconcepts Logo"
            className="mx-auto h-16"
          />
          <button
            data-collapse-toggle="navbar-cta"
            type="button"
            onClick={toggleMenu}
            className="inline-flex items-center p-2 w-10 h-10 justify-right text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
            aria-controls="navbar-cta"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                strokewidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        {/* Mobile menu */}
        {isMenuOpen && (
          <div
            className="fixed top-0 left-0 right-0 bottom-0 bg-white p-4 md:hidden z-10"
            onClick={toggleMenu}
          >
            <ul className="flex flex-col font-medium p-4 space-y-4">
              <li>
                <Link
                  to="/"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                  onClick={() => {
                    trackLinkClick('Home Link'); // Track link click
                  }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                  onClick={() => {
                    trackLinkClick('About Link'); // Track link click
                  }}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                  onClick={() => {
                    trackLinkClick('Services Link'); // Track link click
                  }}
                >
                  Services
                </Link>
                <Link
                  to="/gallery"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                  onClick={() => {
                    trackLinkClick('Gallery Link'); // Track link click
                  }}
                >
                  Gallery
                </Link>
              </li>
            </ul>
          </div>
        )}
        {/* Desktop menu */}
        <div
          className={`items-center justify-between hidden w-full md:flex md:w-auto md:order-1 ${
            isMenuOpen ? 'hidden' : ''
          }`}
          id="navbar-cta"
        >
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-transparent ">
            <li>
              <Link
                to="/"
                className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 "
                aria-current="page"
                onClick={() => {
                  trackLinkClick('Home Link'); // Track link click
                }}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                onClick={() => {
                  trackLinkClick('About Link'); // Track link click
                }}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                onClick={() => {
                  trackLinkClick('Services Link'); // Track link click
                }}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/gallery"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                onClick={() => {
                  trackLinkClick('Gallery Link'); // Track link click
                }}
              >
                Gallery
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
