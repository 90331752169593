import HomePage from './homepage';
import Navbar from './navbar';
import Footer from './footer';
import AboutUs from './Aboutus';
import Services from './Services';
import Gallery from './gallery';
import Hafele from './hafele';
import Login from './Login'; // Import the Login component
import AdminPanel from './AdminPanel'; // Admin panel component
import { Route, Routes, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

ReactGA.initialize('G-3B1EJBPJBH');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const navigate = useNavigate();

  // Protect admin route with simple authentication
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (!isAuthenticated && window.location.pathname === '/admin') {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="services" element={<Services />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="hafele" element={<Hafele />} />
        <Route path="login" element={<Login />} /> {/* New Login Route */}
        <Route path="admin" element={<AdminPanel />} /> {/* Protected Admin Route */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
