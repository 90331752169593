import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";

const Stats = () => {
  const [residential, setResidential] = useState(0);
  const [apartment, setApartment] = useState(0);
  const [commercial, setCommercial] = useState(0);
  const [showStats, setShowStats] = useState(false);
  const { ref, inView, entry } = useInView({
    triggerOnce: false,
    threshold: 0.2,
    rootMargin: "-10px 0px",
  });

  useEffect(() => {
    // Fetch stats from the server
    fetch('http://localhost:5000/api/get-stats')
      .then(response => response.json())
      .then(data => {
        if (data.stats) {
          setResidential(data.stats.residential);
          setApartment(data.stats.apartment);
          setCommercial(data.stats.commercial);
        }
      })
      .catch(error => console.error('Error fetching stats:', error));
  }, []);

  useEffect(() => {
    if (entry && entry.isIntersecting) {
      setShowStats(true);
    }
  }, [entry]);

  return (
    <div
      ref={ref}
      className={`bg-blue-100 py-12 sm:py-12 ${
        showStats ? "opacity-100 translate-y-0" : "opacity-0 translate-y-16"
      } transition-opacity duration-1000 ease-in-out transform`}
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
          <div className="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt className="text-base leading-7 text-gray-600">
              + Residential Projects
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              {showStats && <CountUp end={residential} duration={2} separator="," />}
            </dd>
          </div>
          <div className="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt className="text-base leading-7 text-gray-600">
              + Apartment Projects
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              {showStats && <CountUp end={apartment} duration={2} separator="," />}
            </dd>
          </div>
          <div className="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt className="text-base leading-7 text-gray-600">
              + Commercial Projects
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              {showStats && <CountUp end={commercial} duration={2} separator="," />}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default Stats;
