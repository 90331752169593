import React from "react";
import Maps from "./Map";
import suresh from "./assets/photocandidate.jpeg"; // Import the local image
import archana from "./assets/archana.jpeg";
import ruchir from "./assets/ruchir.jpeg";
import anand from "./assets/anand.jpg"
const AboutUs = () => {

  const people = [
    {
      name: 'Anand Kannan',
      role: 'Founder & Chief',
      imageUrl:
        anand, // Replace with the actual image URL
    },
    {
      name: 'Suresh',
      role: 'CFO',
      imageUrl: suresh, // Use the imported local image
    },
    {
      name: 'Ruchir',
      role: 'Chief Structural',
      imageUrl:
        ruchir, // Make sure the relative path is correct
    },
    {
      name: 'Archana',
      role: 'Chief Architect',
      imageUrl:
        archana, // Replace with the actual image URL
    },
    // More people...
  ];


  return (
    <div className="bg-white py-16 sm:py-20 lg:py-24 ">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            About Our Company
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Welcome to Multiconcepts parent company of  <b>Paramount Enterprises</b> where creativity meets excellence in construction. With a legacy spanning over 25 years, we take immense pride in shaping dreams and crafting spaces that inspire. 
          </p>
        </div>
        <div className="mt-16 prose lg:prose-xl mb-12">
          <p>
          <b>Our Journey:</b>
          <br />

At Paramount Enterprises, our journey began more than two decades ago with a vision to redefine the art of construction. Throughout these years, we have transformed countless ideas into reality, creating architectural marvels that stand the test of time. Our unwavering commitment to quality, innovation, and customer satisfaction has made us a trusted name in the industry.
          </p>
          <br />
          <p>
          <b>Unparalleled Expertise:</b>
          <br />

          What sets us apart is our team of in-house architects, structural engineers, 2D & 3D designers, and a construction crew equipped with skill and knowledge. Each member of our team brings in a wealth of expertise to the table, working with perfect harmony to deliver projects that exceed all expectations. We combine creative vision with technical expertise, ensuring every project is an embodiment of art and engineering put together.
          </p>
          <br />
          <p>
          <b>Our Philosophy:</b>
          <br />

          At Paramount Enterprises, our philosophy revolves around three core principles: Trust, Quality, and Transperency.
          </p>
          <br />
          <p>
          <b>Client-Centric Approach:</b>
          <br />

          At Paramount Enterprises, we keep our clients at the heart of everything we do. We take our time to understand their requirements, preferences, and needs, tailoring solutions to suit their vision. With a keen eye for detail and a passion for perfection, we bring dreams to life, ensuring an experience that is seamless and stress-free.
          </p>
          <br />
          <p>
          <b>Let's Build Together:</b>
          <br />

          At Paramount Enterprises, we believe that every project is an opportunity to create something extraordinary. If you have a vision that you wish to bring to life and are looking for a reliable expert to turn your dreams into reality, we invite you to join hands with us. Together, let's embark on a journey of creativity, quality, and trust, and build your world that inspires generations to come.
          </p>
        </div> 
      </div>
      <div className="bg-blue-100 p-8 flex justify-left ">
      <ol className="relative border-l border-gray-200 dark:border-gray-700">
        <li className="mb-10 ml-4">
          <div className="absolute w-3 h-3 bg-green-400 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">The year we started</time>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">1999</h3>
          <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">The first residential project</p>
          
        </li>
        <li className="mb-10 ml-4">
          <div className="absolute w-3 h-3 bg-green-400 rounded-full mt-1.5 -left-1.5 border border-white dark:border-yellow-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">The year we grew</time>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">2003</h3>
          <p className="text-base font-normal text-gray-500 dark:text-gray-400">Completed 8+ residential and Our First step to a Commercial Project</p>
        </li>
        <li className="mb-10 ml-4">
          <div className="absolute w-3 h-3 bg-green-400 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">The Year we transformed</time>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">2006</h3>
          <p className="text-base font-normal text-gray-500 dark:text-gray-400">Completed 17+ residential, 3+ commercial and started with Our First Appartment project</p>
        </li>
        <li className="ml-4">
          <div className="absolute w-3 h-3 bg-green-400 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Our Current year</time>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">2023</h3>
          <p className="text-base font-normal text-gray-500 dark:text-gray-400">completed 60+ Residential, 6+ Appartment, 4+ commercial Projects and more ongoing.</p>
        </li>
      </ol>
    </div>
      <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our leadership</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            A leadership by 25+ year experienced team.
          </p>
        </div>
        <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
          {people.map((person) => (
            <li key={person.name}>
              <div className="flex items-center gap-x-6">
                <img className="h-16 w-16 rounded-full" src={person.imageUrl} alt="" />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                  <p className="text-sm font-semibold leading-6 text-indigo-600">{person.role}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
    <Maps />
    </div>
    
  );
};

export default AboutUs;
