import React from "react";
import { motion } from 'framer-motion';

const Incentive = () => {
  return (
    < motion bottom cascade>
    <div className="bg-blue-100 py-16 sm:py-20 lg:py-24">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Our Promise: Quality, Time, and Cost
          </h2>
          <p className="mt-4 text-lg text-gray-600">
          At Paramount Enterprises, our commitment goes beyond construction; it's a promise etched in every project we undertake. We understand that your vision deserves nothing less than the best. Our unwavering focus on three core pillars—Quality, Time, and Design—sets us apart and ensures your satisfaction every step of the way.
          </p>
        </div>
        <div className="mt-16 grid gap-8 lg:grid-cols-3">
          <div className="flex flex-col items-center">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center w-12 h-12 text-white bg-blue-500 rounded-md">
                {/* Example icon */}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
</svg>

              </div>
            </div>
            <h3 className="mt-4 text-lg font-medium text-gray-900">Crafting Perfection</h3>
            <p className="mt-2 text-gray-600">Quality is the cornerstone of our work. We believe that excellence is achieved through attention to detail, the use of top-tier materials, and a dedication to the highest construction standards. Each project is a testament to our unwavering pursuit of perfection, resulting in structures that stand the test of time.</p>
          </div>
          <div className="flex flex-col items-center">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center w-12 h-12 text-white bg-blue-500 rounded-md">
                {/* Example icon */}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

              </div>
            </div>
            <h3 className="mt-4 text-lg font-medium text-gray-900">Punctuality at its Best</h3>
            <p className="mt-2 text-gray-600">We value your time as much as you do. Our streamlined processes and expert project management ensure that deadlines are not just met but exceeded. We understand the significance of timely project delivery, and our efficient practices make it possible without compromising on quality or design.</p>
          </div>
          <div className="flex flex-col items-center">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center w-12 h-12 text-white bg-blue-500 rounded-md">
                {/* Example icon */}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
</svg>

              </div>
            </div>
            <h3 className="mt-4 text-lg font-medium text-gray-900">Transparency and Value</h3>
            <p className="mt-2 text-gray-600">We comprehend the significance of managing costs without compromising on quality. Our transparent pricing, combined with effective resource management, ensures that your project is not just a financial investment but a valuable endeavor that delivers remarkable returns.</p>
          </div>
        </div>
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl text-center mt-12">
        Our Assurance: Your Vision, Our Priority
          </h2>
          <p className="mt-4 text-lg text-gray-600 text-center">
          By selecting Paramount Enterprises, you're choosing a partner dedicated to transforming your aspirations into reality. Our commitment to Quality, Time, and Cost excellence goes beyond words—it's a commitment that's reflected in the satisfaction of our clients and the structures that stand as a testament to our dedication.
<br />
Experience the Paramount Promise firsthand. Reach out to us today to embark on a journey where your vision is nurtured, executed with precision, and celebrated through exceptional quality, timely delivery, and cost-effectiveness. Your dreams, our unwavering commitment.
          </p>
      </div>
    </div>
    </motion>
  );
};

export default Incentive;
