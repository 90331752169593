import React from "react";
import Miniaboutus from "./Miniaboutus"; // Update the path to your Navbar component
import Herostylehome from "./components/herostylehome";
import Stats from "./Stats";
import Service from "./serviceshome";
import Pnp from './PNP'
import Incentive from "./Incentive";
import Section from "./components/section";
import { ReactDOM } from "react";
import ReactGA from 'react-ga';
ReactGA.initialize('G-3B1EJBPJBH');
ReactGA.pageview(window.location.pathname + window.location.search);
const HomePage = () => {
  return (
    <div>
    <Herostylehome />

    <Miniaboutus />
    <Stats />
    <Service />
    <Incentive />
    <Pnp />
    <Section />
        {/* Your hero section content goes here */}
      

      {/* Other sections of the homepage */}
    </div>
    
  );
};

export default HomePage;
