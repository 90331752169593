import React, { useState, useEffect } from 'react';

const StatsManagement = () => {
  const [residential, setResidential] = useState(0);
  const [apartment, setApartment] = useState(0);
  const [commercial, setCommercial] = useState(0);
  const [message, setMessage] = useState('');

  // Fetch the current stats from the server on component mount
  useEffect(() => {
    fetch('http://localhost:5000/api/get-stats') // Adjust the URL as necessary
      .then(response => response.json())
      .then(data => {
        if (data.stats) {
          setResidential(data.stats.residential);
          setApartment(data.stats.apartment);
          setCommercial(data.stats.commercial);
        }
      })
      .catch(error => console.error('Error fetching stats:', error));
  }, []);

  const handleUpdateStats = (e) => {
    e.preventDefault();

    fetch('http://localhost:5000/api/update-stats', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ residential, apartment, commercial }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      setMessage(data.message);
      
      // Clear the message after 3 seconds
      setTimeout(() => {
        setMessage('');
      }, 3000);
    })
    .catch(error => {
      console.error('Error updating stats:', error);
      setMessage('Error updating stats');
    });
  };

  return (
    <div className="p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-semibold">Update Stats</h2>
      <form onSubmit={handleUpdateStats} className="space-y-4">
        <div>
          <label className="block text-sm font-medium">Residential Projects</label>
          <input
            type="number"
            value={residential}
            onChange={(e) => setResidential(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-400"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Apartment Projects</label>
          <input
            type="number"
            value={apartment}
            onChange={(e) => setApartment(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-400"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Commercial Projects</label>
          <input
            type="number"
            value={commercial}
            onChange={(e) => setCommercial(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-400"
            required
          />
        </div>
        <button
          type="submit"
          className="mt-4 w-full bg-blue-500 text-white font-semibold py-2 rounded-md hover:bg-blue-600 transition duration-200"
        >
          Update Stats
        </button>
      </form>
      {message && <p className="mt-4 text-green-600">{message}</p>}
    </div>
  );
};

export default StatsManagement;
