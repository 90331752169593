import React from 'react';
import { useNavigate } from 'react-router-dom';
import UploadImage from '../src/components/UploadImage'; // Make sure the path is correct
import StatsManagement from '../src/StatsManagement'
const AdminPanel = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated'); // Remove authentication token
    navigate('/login'); // Redirect to login page
  };

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-4">Admin Panel</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="p-6 bg-white shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold">Manage Gallery</h2>
          <p className="mt-2">Upload images from the gallery.</p>
          <UploadImage /> {/* Include the upload functionality here */}
        </div>
        <div className="p-6 bg-white shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold">Manage Content</h2>
          <p className="mt-2">Update content on different pages of the site.</p>
          <StatsManagement /> {/* Include the new StatsManagement component */}
        </div>
      </div>

      {/* Logout Button */}
      <div className="mt-8">
        <button
          onClick={handleLogout}
          className="w-full bg-red-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-red-600 transition duration-200"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default AdminPanel;
