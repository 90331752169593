import React from 'react';
import hafelelogo from '../assets/HAFELE.png';

const Section = () => {
    const handleDownloadPDF = () => {
        // Use an absolute path to the PDF file in the public folder
        const pdfFile = '/pdfs/HAFELE DESIGNLINE MRP PRICELIST JUNE 2023 (25.05.23).pdf';
      
        // Create an anchor element
        const anchor = document.createElement('a');
        anchor.href = pdfFile;
        anchor.target = '_blank'; // Open in a new tab/window
        anchor.download = 'HAFELE DESIGNLINE MRP PRICELIST JUNE 2023 (25.05.23).pdf'; // File name when downloading
      
        // Trigger a click event on the anchor element to initiate the download
        anchor.click();
      };
  return (
    <section className="bg-blue-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {/* Left Column (Image) */}
          <div className="max-w-lg ">
            <img
              className="h-64 w-full object-cover "
              src={hafelelogo}
              alt="Image"
            />
          </div>

          {/* Right Column (Content and Buttons) */}
          <div className="mt-8 lg:mt-0">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
              Project Distributor for HAFELE From 2012
            </h2>
            <p className="mt-3 text-lg text-gray-500">
            Paramount Enterprises, a trusted name in the industry, has been the project distributor for HAFELE since 2012. With a decade of unwavering commitment, we've been providing top-quality HAFELE products to transform projects into remarkable successes. Our partnership with HAFELE reflects our dedication to excellence in the field of project distribution.
            </p>

            {/* Buttons */}
            <div className="mt-6">
              <a
                href="https://www.hafeleindia.com/en/info/service/407/5298/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-base font-medium text-indigo-600 hover:text-indigo-500"
              >
                Visit Website
              </a>
              <button
          onClick={handleDownloadPDF}
          className="ml-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
                HAFELE DESIGNLINE
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section;
