import React, { useState } from 'react';
import axios from 'axios';

const UploadImage = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [message, setMessage] = useState('');

  const handleImageChange = (e) => {
    setSelectedImages([...e.target.files]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (selectedImages.length === 0) {
      setMessage('Please select images to upload.');
      return;
    }

    const formData = new FormData();
    selectedImages.forEach((image) => formData.append('images', image));

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('http://localhost:5000/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      setMessage('Images uploaded successfully!');

      // Clear the message after 3 seconds and reset selected images
      setTimeout(() => {
        setMessage('');
        setSelectedImages([]);
      }, 3000);
    } catch (error) {
      setMessage('Error uploading images: ' + error.message);
      setTimeout(() => {
        setMessage('');
      }, 3000); // Clear the error message after 3 seconds
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
      <h2 className="text-2xl font-bold text-center mb-6">Upload Images</h2>
      <form onSubmit={handleUpload} className="space-y-6">
        <div className="flex flex-col">
          <label htmlFor="imageUpload" className="text-sm font-semibold mb-2">Select Images to Upload:</label>
          <input
            id="imageUpload"
            type="file"
            multiple
            onChange={handleImageChange}
            className="border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200"
        >
          Upload Images
        </button>

        {message && <p className="text-center mt-4 text-green-500 font-semibold">{message}</p>}
      </form>
    </div>
  );
};

export default UploadImage;
