import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Fetch images from backend
  const fetchImages = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/images');
      setImages(response.data.images.map(image => `http://localhost:5000/uploads/${image}`));
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="container mx-auto px-4 py-6">
      {/* Carousel for Uploaded Images */}
      <div className="relative mb-8">
        {images.length > 0 ? (
          <div className="flex items-center justify-center relative">
            <button
              onClick={prevImage}
              className="absolute left-0 bg-white bg-opacity-80 rounded-full p-3 m-2 shadow-md transition duration-300 hover:bg-opacity-100 transform hover:scale-110"
              aria-label="Previous Image"
            >
              <svg
                className="w-6 h-6 text-gray-800"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <img
              src={images[currentIndex]} // Display the current image
              className="w-full h-96 object-cover rounded-lg shadow-lg transition-transform duration-500"
              alt="Slide"
            />
            <button
              onClick={nextImage}
              className="absolute right-0 bg-white bg-opacity-80 rounded-full p-3 m-2 shadow-md transition duration-300 hover:bg-opacity-100 transform hover:scale-110"
              aria-label="Next Image"
            >
              <svg
                className="w-6 h-6 text-gray-800"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        ) : (
          <p className="text-center text-gray-500">No images available</p>
        )}
      </div>

      {/* Display Uploaded Images in Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div className="border rounded-lg overflow-hidden shadow-lg transition transform duration-300 hover:scale-105" key={index}>
            <img
              src={image}
              className="w-full h-48 object-cover"
              alt={`Image ${index + 1}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
