import React from "react";
import { Link, useHref } from "react-router-dom";
import ReactGA from 'react-ga';
const PartnersAndPromoters = () => {
  ReactGA.initialize('G-3B1EJBPJBH');
  const trackLinkClick = (label) => {
    // Track link clicks in Google Analytics
    ReactGA.event({
      category: 'Link Click',
      action: 'Click',
      label: label, // Use a descriptive label for your link
    });
  };

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
          Trusted by the world’s most innovative teams
        </h2>
        
        <div className="mx-auto mt-4 grid max-w-lg grid-cols-2 gap-x-8 gap-y-10 sm:max-w-xl sm:grid-col-2 sm:gap-x-6 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {/* Image and Text Container */}
          <div className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 flex flex-col items-center">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR04m6D8yEzUBBnYTn1YAxdZbNJwNdIlj4jMQ&usqp=CAU"
              alt="Transistor"
              width={158}
              height={48}
            />
            
            
          </div>
          <img
            className=" col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://www.enox.in/site-images/enox-dp/ENOX%20logo%20PNG.png"
            alt="Reform"
            width={158}
            height={48}
          />
          
          <img
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src="https://yaleonline.in/cdn/shop/files/yale_logo_100_100_706d5f62-c97d-415c-ae31-e801c1177fe6.png?v=1659940981&width=100"
            alt="SavvyCal"
            width={158}
            height={48}
          />
          <img
            className="col-span-2  max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src="https://www.ozonehardware.com/images/logo.jpg"
            alt="Statamic"
            width={158}
            height={48}
          />
          
        </div>
      </div>
    </div>
  );
};

export default PartnersAndPromoters;
